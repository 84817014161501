import { addons } from '@storybook/addons';
import ccxTheme from './ccxTheme';

addons.setConfig(
  /** @type {import('@storybook/manager-js').Addon_Config} */
  {
    theme: ccxTheme,
    showPanel: false,
    enableShortcuts: false,
    sidebar: {
      showRoots: true,
    },
  }
);
