import { create } from '@storybook/theming/create';
import logo from '../src/assets/juliusbaer-logo.svg';

export default create({
  base: 'light',
  brandUrl: 'https://zafire.ccx.sehlat.io',
  brandTitle: 'Julius Bär / Zafire',
  brandImage: logo,
  fontBase: '"Verlag SSm For Baer", sans-serif',

  //
  colorPrimary: '#2d47dc',
  colorSecondary: '#2d47dc',

  // UI
  appBg: '#f8f9fb',
  appContentBg: '#fcfdfd',
  appBorderColor: '#e5e7eb',
  appBorderRadius: 4,

  // Text colors
  textColor: '#10162F',
  textInverseColor: '#ffffff',

  // Toolbar default and active colors
  barTextColor: '#10162F',
  barSelectedColor: '#2d47dc',
  barBg: '#f8f9fb',
});
